.wrapper {
    background-color: #fff!important;
}

.login-box-body {
    background-color: #eee;
}

.login-box button {
    margin-left: 0px;
}

.login-box a:hover {
    cursor: pointer;
}
  